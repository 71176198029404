import React from 'react';
import { BaseProps } from '../../types';

interface CheckboxProps extends BaseProps {
  isChecked: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  label?: string;
}

const Checkbox = ({ isChecked, onChange, label, className }: CheckboxProps) => {
  return (
    <div className={className}>
      <label>
        <input type="checkbox" id={label} checked={isChecked} onChange={onChange} />
        {label && <span className="ml-2">{label}</span>}
      </label>
    </div>
  );
};
export default Checkbox;
