import React from 'react';
import { Button, Dropdown, Input, LoadingModal, Text, TextArea } from '../../../components/base';
import CardHeader, { CardHeaderVariant } from '../../../components/assessmentAppLayouts/CardHeader';
import * as assessmentApiClient from '../../../api/assessment';
import { Tester, TesterType } from '../../../model/tester';
import { GenerateCodeFormData } from '../../../model/generateCode';
import { REGISTRATION_TYPE_OPTIONS } from '../../assessmentApp/Registration/constants';
import { DropdownOption } from '../../../components/base/Form/Dropdown';
import CreateModal from './components/CreateModal';
import CreatingTestersTable from './components/CreatingTestersTable';
import { downloadFile } from '../../../utils/downloadFile';
import CreateEmptyCodeModal from './components/CreateEmptyCodeModal';
import { Checkbox } from '../../../components/base/Form';

const formatToGenerateCodeFormData = (
  prefix: string,
  testers: Tester[],
  usePretestV2: boolean,
  speakingTestId?: string
): GenerateCodeFormData => {
  return {
    codePrefix: prefix,
    testers: testers,
    speakingTestId: speakingTestId,
    usePretestV2,
  };
};

const CodeGenerate = () => {
  const [loading, setLoading] = React.useState(false);
  const [codePrefix, setCodePrefix] = React.useState<string>('');
  const [testers, setTesters] = React.useState<Tester[]>([]);
  const [schoolOptions, setSchoolOption] = React.useState<DropdownOption[]>([
    { value: '', text: '' },
  ]);
  const [csv, setCsv] = React.useState('');
  const [parseData, setParseData] = React.useState(false);
  const [schoolId, setSchoolId] = React.useState('');
  const [type, setType] = React.useState<TesterType>(TesterType.Student);
  const [createModalOpen, setCreateModalOpen] = React.useState(false);
  const [speakingTestId, setSpeakingTestId] = React.useState('');
  const [createEmptyCodeModalOpen, setCreateEmptyCodeModalOpen] = React.useState(false);
  const [usePretestV2, setUsePretestV2] = React.useState(false);

  const formatExample = {
    [TesterType.Student]: ' prefix, firstname, lastname, grade, room',
    [TesterType.Teacher]: ' prefix, firstname, lastname, email, phone',
    [TesterType.BusinessEmployee]: ' prefix, firstname, lastname, email, phone, organization',
  };

  const optionalExample = {
    [TesterType.Student]: ' grade, room, organization',
    [TesterType.Teacher]: ' email, phone, organization',
    [TesterType.BusinessEmployee]: ' email, phone',
  };

  React.useEffect(() => {
    const initializeData = async () => {
      setLoading(true);
      try {
        const schools = await assessmentApiClient.getAllSchools();
        const schoolOptions = schools.map((school) => {
          return { text: school.name, value: school.id };
        });
        setSchoolOption(schoolOptions);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    initializeData();
  }, []);

  const handleCreateSchool = async () => {
    setCreateModalOpen(true);
  };

  const handleParseData = async () => {
    const testers = csv.split('\n');
    if (!schoolId) {
      alert('Please Select School');
    } else {
      const parsedTesters = testers.map((tester, i) => {
        const data: string[] = tester.split(',');
        const trimmedData = data.map((d) => d.trim());
        const dataToParse = {
          id: `${i}`,
          prefix: trimmedData[0],
          firstName: trimmedData[1],
          lastName: trimmedData[2],
          fullName: `${trimmedData[1]} ${trimmedData[2]}`,
          grade: type === TesterType.Student ? trimmedData[3] : undefined,
          room: type === TesterType.Student ? trimmedData[4] : undefined,
          email: type === TesterType.Teacher ? trimmedData[3] : undefined,
          phoneNo: type === TesterType.Teacher ? trimmedData[4] : undefined,
          type: type,
          schoolId: schoolId,
        };
        return Tester.parse(dataToParse);
      });
      setTesters(parsedTesters);
      setParseData(true);
    }
  };

  const handleGenerateCode = async () => {
    try {
      setLoading(true);
      const generateCodeFormData = formatToGenerateCodeFormData(
        codePrefix,
        testers,
        usePretestV2,
        speakingTestId
      );
      const csv = await assessmentApiClient.createAccessCodeForSchool(generateCodeFormData);
      downloadFile(new Blob([csv], { type: 'text/csv' }));
      alert('Generate code success!');
    } catch (error) {
      console.error(error);
      alert(error);
    } finally {
      setLoading(false);
      resetFormData();
    }
  };
  const resetFormData = () => {
    setCodePrefix('');
    setSchoolId('');
    setTesters([]);
    setParseData(false);
    setCsv('');
  };
  const handleChangePrefiex = (e: React.FormEvent<HTMLInputElement>) => {
    setCodePrefix(e.currentTarget.value);
  };
  const handleTesterTypeDropdownChange = (e: React.FormEvent<HTMLSelectElement>) => {
    if ((Object as any).values(TesterType).includes(e.currentTarget.value)) {
      setType(e.currentTarget.value as TesterType);
    }
  };

  const handleSchoolIdDropdownChange = (e: React.FormEvent<HTMLSelectElement>) => {
    setSchoolId(e.currentTarget.value);
  };

  const handleChangeCsv = (e: React.FormEvent<HTMLTextAreaElement>) => {
    setCsv(e.currentTarget.value);
  };

  const handleCancelSession = () => {
    setCreateModalOpen(false);
  };

  const handleChangeSpeakingTestId = (e: React.FormEvent<HTMLInputElement>) => {
    setSpeakingTestId(e.currentTarget.value);
  };

  const handleContinueSession = async (schoolName: string) => {
    setLoading(true);
    await assessmentApiClient.createSchoolByName(schoolName);

    const schools = await assessmentApiClient.getAllSchools();
    const schoolOptions = schools.map((school) => {
      return { text: school.name, value: school.id };
    });
    setSchoolOption(schoolOptions);

    setLoading(false);
    setCreateModalOpen(false);
  };

  const handleCreateEmptyCode = () => {
    setCreateEmptyCodeModalOpen(true);
  };

  const handleCloseCreatingEmptyCode = () => {
    setCreateEmptyCodeModalOpen(false);
  };

  const handleUsePretestV2Change = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUsePretestV2(e.target.checked);
  };

  return (
    <div className="flex flex-col">
      <CardHeader
        variant={CardHeaderVariant.PRIMARY}
        subtitle=""
        title="Generate Access Code"
        description=""
        className="mb-6"
      />

      <div className="flex flex-row gap-4 w-full">
        <Dropdown
          value={schoolId || ''}
          onChange={(e: React.FormEvent<HTMLSelectElement>) => handleSchoolIdDropdownChange(e)}
          placeholder="Select School"
          options={schoolOptions}
          className="mb-6"
        />
        <Button variant="secondary" onClick={handleCreateSchool} className="h-fit w-fit mt-2">
          Create School
        </Button>
      </div>

      <Button
        variant="primary"
        onClick={handleCreateEmptyCode}
        disabled={!schoolId}
        className="w-fit mb-6"
      >
        Create Empty Code
      </Button>

      <Input
        value={codePrefix}
        onChange={handleChangePrefiex}
        placeholder="School Code Prefix"
        className="flex w-fit mb-6"
      />
      <Dropdown
        value={type || ''}
        onChange={(e: React.FormEvent<HTMLSelectElement>) => handleTesterTypeDropdownChange(e)}
        placeholder="Selete Tester Type"
        options={REGISTRATION_TYPE_OPTIONS}
        className="mb-6 w-fit "
      />
      <Input
        value={speakingTestId}
        onChange={handleChangeSpeakingTestId}
        placeholder="Speaking Test ID"
        className="flex w-fit mb-6"
      />
      <Checkbox
        label="Use Pretest V2"
        isChecked={usePretestV2}
        onChange={handleUsePretestV2Change}
        className="mb-12 w-full"
      />
      <Text is="p" className="mb-6">
        ใส่ข้อมูล Tester ใน CSV formatted ตาม format นี้ <br />
        <br />
        format:
        {formatExample[type]}
        <br />
        Optional: {optionalExample[type]}
      </Text>
      <TextArea
        value={csv}
        onChange={handleChangeCsv}
        placeholder={''}
        className={'min-h-[150px] w-[450px] mb-4'}
      />

      <div>
        <Button
          variant="primary"
          onClick={handleParseData}
          className="mb-12"
          disabled={!csv || !schoolId}
        >
          Parse Data
        </Button>
      </div>
      <div className="w-full text-center">
        {parseData ? <CreatingTestersTable testers={testers} type={type} /> : null}

        {parseData && (
          <Button
            variant="primary"
            onClick={handleGenerateCode}
            className="mt-6"
            disabled={!schoolId || testers.length <= 0 || !parseData}
          >
            Generate Codes
          </Button>
        )}
      </div>
      <CreateModal
        open={createModalOpen}
        onSecondaryCtaClick={handleCancelSession}
        onPrimaryCtaClick={handleContinueSession}
      />
      {schoolId && createEmptyCodeModalOpen && (
        <CreateEmptyCodeModal
          open={createEmptyCodeModalOpen}
          onSecondaryCtaClick={handleCloseCreatingEmptyCode}
          schoolId={schoolId}
          onCreateCode={handleCloseCreatingEmptyCode}
        />
      )}

      <LoadingModal open={loading} />
    </div>
  );
};

export default CodeGenerate;
