import { AccessCode } from '../model/accessCode';
import { Tester } from '../model/tester';
import { School } from '../model/school';
import { RegistrationFormData } from '../model/registrationData';
import { PreTestQuestionAnswer, PreTestQuestionSet } from '../model/preTestQuestion';
import { SpeakingTestQuestion } from '../model/speakingTestQuestion';
import { BASE_URL } from './constant';
import { getRequest, postRequest } from './utils';
import { Test } from '../model/test';
import { GenerateCodeFormData } from '../model/generateCode';
import { MimeTypes } from '../routes/assessmentApp/RecordingButton/useMediaRecorder';

export const getAccessCodeByCode = async (code: string): Promise<AccessCode> => {
  try {
    const fetchUrl = new URL(`/access-codes/${code}`, BASE_URL);
    const data = await getRequest(fetchUrl);
    const accessCode = AccessCode.parse(data);

    return accessCode;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getAccessCodeById = async (accessCodeId: string): Promise<AccessCode> => {
  try {
    const fetchUrl = new URL(`/access-codes/id/${accessCodeId}`, BASE_URL);
    const data = await getRequest(fetchUrl);
    const accessCode = AccessCode.parse(data);

    return accessCode;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getTesterById = async (testerId: string): Promise<Tester> => {
  try {
    const fetchUrl = new URL(`/testers/${testerId}`, BASE_URL);
    const data = await getRequest(fetchUrl);
    const tester = Tester.parse(data);

    return tester;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getSchoolById = async (schoolId: string): Promise<School> => {
  try {
    const fetchUrl = new URL(`/schools/${schoolId}`, BASE_URL);
    const data = await getRequest(fetchUrl);
    const school = School.parse(data);

    return school;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const registerTester = async (
  accessCode: AccessCode,
  formData: RegistrationFormData
): Promise<{ testId: string; tester: Tester }> => {
  let option: RequestInit | undefined;
  if (!accessCode.testerId) {
    option = {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(formData),
    };
  }

  try {
    const fetchUrl = new URL(`/access-codes/${accessCode.code}/register`, BASE_URL);
    const { testId, tester } = await postRequest(fetchUrl, option);
    const parsedTester = Tester.parse(tester);

    return { testId, tester: parsedTester };
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const startPreTestQuiz = async (testId: string): Promise<PreTestQuestionSet> => {
  try {
    const fetchUrl = new URL(`/tests/${testId}/start-pretest`, BASE_URL);
    const data = await postRequest(fetchUrl);
    const preTestQuestionSet = PreTestQuestionSet.parse(data);

    return preTestQuestionSet;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

/**
 * Submit question set and get new question set
 */
export const submitPreTestQuestionSet = async (
  testId: string,
  questionSetId: string,
  questionAnswers: PreTestQuestionAnswer[]
): Promise<{ completed: boolean; questionSet?: PreTestQuestionSet }> => {
  try {
    const fetchUrl = new URL(`/tests/${testId}/submit-pretest`, BASE_URL);
    const { completed, questionSet } = await postRequest(fetchUrl, {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        questionSetId: questionSetId,
        questionAnswers: questionAnswers,
      }),
    });

    const preTestQuestionSet = questionSet ? PreTestQuestionSet.parse(questionSet) : undefined;

    return { completed, questionSet: preTestQuestionSet };
  } catch (error) {
    console.error(error);
    throw error;
  }
};

/**
 * Get Speaking Test Questions
 * Backend will send back all questions
 */
export const getSpeakingTestQuestions = async (testId: string): Promise<SpeakingTestQuestion[]> => {
  try {
    const fetchUrl = new URL(`tests/${testId}/speaking-tests`, BASE_URL);
    const response = await getRequest(fetchUrl);
    const data = response.map((item: any) => {
      return SpeakingTestQuestion.parse(item);
    });
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getSingleSpeakingTestQuestion = async (
  speakingQuestionId: string
): Promise<SpeakingTestQuestion> => {
  try {
    const fetchUrl = new URL(`speaking-tests/questions/${speakingQuestionId}`, BASE_URL);
    const response = await getRequest(fetchUrl);
    return SpeakingTestQuestion.parse(response);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getAllSpeakingTestQuestions = async (): Promise<SpeakingTestQuestion[]> => {
  try {
    const fetchUrl = new URL(`speaking-tests/questions`, BASE_URL);
    const response = await getRequest(fetchUrl);
    return response.map((question: { [key: string]: any }) => SpeakingTestQuestion.parse(question));
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const getFileName = (testId: string, speakingTestQuestionId: string, mimeType: MimeTypes) => {
  const fileName = `${testId}-${speakingTestQuestionId}`;

  switch (mimeType) {
    case MimeTypes.mp4:
      return `${fileName}.mp4`;
    case MimeTypes.webm:
      return `${fileName}.webm`;
    default:
      throw new Error('mimeType not supported');
  }
};
export const submitSpeakingTestQuestion = async (
  testId: string,
  speakingTestQuestionId: string,
  audioBlob: Blob,
  mimeType: MimeTypes
): Promise<{ completed: boolean }> => {
  try {
    const fetchUrl = new URL(`tests/${testId}/speaking-tests/${speakingTestQuestionId}`, BASE_URL);
    const formData = new FormData();
    const fileName = getFileName(testId, speakingTestQuestionId, mimeType);

    formData.append('file', audioBlob, fileName);

    const { completed } = await postRequest(fetchUrl, {
      body: formData,
      headers: new Headers({
        enctype: 'multipart/form-data',
      }),
    });
    return { completed };
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getTestById = async (testId: string) => {
  try {
    const fetchUrl = new URL(`/tests/${testId}`, BASE_URL);
    const data = await getRequest(fetchUrl);
    const test = Test.parse(data);
    return test;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const terminateTestById = async (testId: string) => {
  try {
    const fetchUrl = new URL(`/tests/${testId}/terminate`, BASE_URL);
    await postRequest(fetchUrl);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getLatestTestByAccessCode = async (code: string): Promise<Test | undefined> => {
  try {
    const fetchUrl = new URL(`/access-codes/${code}/latest-test`, BASE_URL);
    const data = await getRequest(fetchUrl);

    if (data === undefined) {
      return data;
    } else {
      const test = Test.parse(data);
      return test;
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getAllSchools = async (): Promise<School[]> => {
  try {
    const fetchUrl = new URL(`/schools`, BASE_URL);
    const response = await getRequest(fetchUrl);
    const data = response.map((item: any) => {
      return School.parse(item);
    });

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const createSchoolByName = async (schoolName: string) => {
  try {
    const fetchUrl = new URL(`/schools`, BASE_URL);
    const response = await postRequest(fetchUrl, {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        name: schoolName,
      }),
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const createAccessCodeForSchool = async (generateCodeForm: GenerateCodeFormData) => {
  try {
    const testers = generateCodeForm.testers.map((tester: Tester) => {
      return {
        type: tester.type,
        firstName: tester.firstName,
        lastName: tester.lastName,
        prefix: tester.prefix,
        schoolId: tester.schoolId,
        grade: tester.grade,
        room: tester.room,
        email: tester.email,
        phoneNo: tester.phoneNo,
      };
    });

    const fetchUrl = new URL(`/access-codes/batch`, BASE_URL);
    const csvText = await postRequest(fetchUrl, {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        codePrefix: generateCodeForm.codePrefix,
        testers: testers,
        speakingTestId: generateCodeForm.speakingTestId,
        usePretestV2: generateCodeForm.usePretestV2,
      }),
    });
    return csvText;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getAccessCodeStatusBySchoolId = async (
  schoolId: string,
  limit: number,
  start: number
) => {
  try {
    const fetchUrl = new URL(
      `/admin/accesscodes/status/${schoolId}?start=${start}&limit=${limit}`,
      BASE_URL
    );
    const response = await getRequest(fetchUrl);
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getAllAccessCodeStatusBySchoolId = async (schoolId: string) => {
  try {
    const fetchUrl = new URL(`/admin/accesscodes/status/all/${schoolId}`, BASE_URL);
    const response = await getRequest(fetchUrl);
    const accessCodeList = response.map(
      (data: { accessCode: AccessCode; tester: Tester; testInstance: Test }) => {
        return {
          accessCode: AccessCode.parse(data.accessCode),
          tester: data.tester ? Tester.parse(data.tester) : null,
          testInstance: data.testInstance ? Test.parse(data.testInstance) : null,
        };
      }
    );
    return accessCodeList;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getTesterBySchoolId = async (schoolId: string): Promise<Tester[]> => {
  try {
    const fetchUrl = new URL(`/testers/school/${schoolId}`, BASE_URL);
    const data = await getRequest(fetchUrl);
    const testers = data.map((tester: Tester) => Tester.parse(tester));
    return testers;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getAccessCodeListBySchoolId = async (
  schoolId: string
): Promise<{ accessCode: AccessCode; tester: Tester }[]> => {
  try {
    const fetchUrl = new URL(`/admin/accesscodes/${schoolId}`, BASE_URL);
    const response = await getRequest(fetchUrl);
    const accessCodeList = response.map((data: { accessCode: AccessCode; tester: Tester }) => {
      return {
        accessCode: AccessCode.parse(data.accessCode),
        tester: data.tester ? Tester.parse(data.tester) : null,
      };
    });
    return accessCodeList;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
