import { useState } from 'react';
import { Heading, Modal, Button, Input, Dropdown, LoadingModal } from '../../../../components/base';
import * as adminApiClient from '../../../../api/admin';
import { AccessCodeType } from '../../../../model/accessCode';
import { DropdownOption } from '../../../../components/base/Form/Dropdown';
import { downloadFile } from '../../../../utils/downloadFile';
import { Checkbox } from '../../../../components/base/Form';

export const accessCodeTypeOptions: DropdownOption[] = [
  { value: AccessCodeType.School, text: AccessCodeType.School },
  { value: AccessCodeType.Business, text: AccessCodeType.Business },
];

interface CreateModalProps {
  open: boolean;
  schoolId: string;
  onSecondaryCtaClick: () => void;
  onCreateCode: () => void;
}

const CreateEmptyCodeModal = ({
  open,
  schoolId,
  onSecondaryCtaClick,
  onCreateCode,
}: CreateModalProps) => {
  const [codePrefix, setCodePrefix] = useState('');
  const [number, setNumber] = useState('');
  const [speakingTestId, setSpeakingTestId] = useState('');
  const [usePretestV2, setUsePretestV2] = useState(false);
  const [type, setAccessCodeType] = useState(AccessCodeType.School);
  const [loading, setLoading] = useState(false);

  const handleCreate = async () => {
    setLoading(true);
    const csv = await adminApiClient.createEmptyAccessCodeForSchool({
      schoolId,
      codePrefix,
      number: parseInt(number),
      type,
      speakingTestId,
      usePretestV2,
    });
    downloadFile(new Blob([csv], { type: 'text/csv' }));
    onCreateCode();
    setLoading(false);
  };

  const handleUsePretestV2Change = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUsePretestV2(e.target.checked);
  };

  return (
    <Modal open={open} size="md">
      <div className="flex flex-col justify-center items-center py-8">
        <Heading variant="h1" className="mb-12">
          Create Empty Code
        </Heading>
        <Dropdown
          value={type || ''}
          onChange={(e: React.FormEvent<HTMLSelectElement>) =>
            setAccessCodeType(e.currentTarget.value as AccessCodeType)
          }
          placeholder="Select Type"
          options={accessCodeTypeOptions}
          className="mb-6 w-full"
        />
        <Input
          value={codePrefix}
          onChange={(e: React.FormEvent<HTMLInputElement>) => setCodePrefix(e.currentTarget.value)}
          placeholder="Code Prefix"
          className="mb-6 w-full"
        />
        <Input
          value={number}
          onChange={(e: React.FormEvent<HTMLInputElement>) => setNumber(e.currentTarget.value)}
          placeholder="Number to generate"
          className="mb-6 w-full"
          type="number"
        />
        <Input
          value={speakingTestId}
          onChange={(e: React.FormEvent<HTMLInputElement>) =>
            setSpeakingTestId(e.currentTarget.value)
          }
          placeholder="Speaking Test Id"
          className="mb-6 w-full"
        />
        <Checkbox
          label="Use Pretest V2"
          isChecked={usePretestV2}
          onChange={handleUsePretestV2Change}
          className="mb-12 w-full"
        />
        <div className="flex gap-6 mb-4 w-full">
          <Button variant="secondary" onClick={onSecondaryCtaClick} className="basis-0 grow">
            Cancel
          </Button>
          <Button variant="primary" onClick={handleCreate} className="basis-0 grow">
            Create
          </Button>
        </div>
      </div>
      <LoadingModal open={loading} />
    </Modal>
  );
};

export default CreateEmptyCodeModal;
