import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { AccessCode } from '../../../../model/accessCode';
import { Tester } from '../../../../model/tester';

interface AccessCodeListTableProps {
  accessCodeList: { accessCode: AccessCode; tester?: Tester }[];
  school: String;
}
const columns: GridColDef[] = [
  {
    field: 'code',
    headerAlign: 'center',
    headerName: 'Code',
    align: 'center',
    flex: 0.2,
  },
  {
    field: 'credits',
    headerAlign: 'center',
    headerName: 'Credits',
    align: 'center',
    flex: 0.1,
  },
  {
    field: 'schoolId',
    headerAlign: 'center',
    headerName: 'School id',
    align: 'center',
    flex: 0.3,
  },
  {
    field: 'school',
    headerAlign: 'center',
    headerName: 'School name',
    align: 'center',
    flex: 0.3,
  },
  {
    field: 'testerId',
    headerAlign: 'center',
    headerName: 'Tester id',
    align: 'center',
    flex: 0.3,
  },
  {
    field: 'fullName',
    headerAlign: 'center',
    headerName: 'Tester name',
    align: 'center',
    flex: 0.3,
  },
  {
    field: 'speakingTestId',
    headerAlign: 'center',
    headerName: 'Speaking Test ID',
    align: 'center',
    flex: 0.3,
  },
  {
    field: 'usePretestV2',
    headerAlign: 'center',
    headerName: 'usePretestV2',
    align: 'center',
    flex: 0.2,
  },
  {
    field: 'type',
    headerAlign: 'center',
    headerName: 'Type',
    align: 'center',
    flex: 0.3,
  },
];

const AccessCodeListTable = ({ accessCodeList, school }: AccessCodeListTableProps) => {
  const rowsData = accessCodeList.map((data) => {
    return {
      code: data.accessCode.code,
      credits: data.accessCode.credits,
      schoolId: data.accessCode.schoolId,
      testerId: data.accessCode.testerId ?? '-',
      school: school,
      fullName: data.tester?.fullName ?? '-',
      speakingTestId: data.accessCode.speakingTestId,
      usePretestV2: data.accessCode.usePretestV2,
      type: data.accessCode.type,
    };
  });
  return (
    <div className="text-center" style={{ width: '100%', overflow: 'auto' }}>
      <div className="w-[1800px]">
        <DataGrid
          getRowId={(row) => row.code}
          rows={rowsData}
          columns={columns}
          disableRowSelectionOnClick
          getRowHeight={() => 'auto'}
          sx={{
            '& .MuiDataGrid-cellContent': {
              overflowWrap: 'break-word',
              wordWrap: 'break-word',
              whiteSpace: 'initial',
              paddingY: 1,
            },
          }}
        />
      </div>
    </div>
  );
};

export default AccessCodeListTable;
